import React from "react"
import Layout from "../components/Layout"
import Pagetitle from "../components/Pagetitle"

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <div className={`row justify-content-center`}>
        <div className="col-lg-7 col-md-10">
          <Pagetitle>Privacy Policy</Pagetitle>
          <p>Last updated: March 27th 2019</p>
          <p>
            This Privacy Policy describes how your personal information is
            collected, used, and shared when you visit our website zengapps.com
            (the &#8220;site&#8221;) or use one of our Shopify Apps (the
            “apps”).
          </p>

          <h2>Personal Information We Collect</h2>
          <p>
            When you visit our site or apps, we automatically collect certain
            information about your device, including information about your web
            browser, IP address, time zone, and some of the cookies that are
            installed on your device. Additionally, as you browse the web, we
            collect information about the individual web pages or products that
            you view, what websites or search terms referred you to the site or
            apps, and information about how you interact with the site or apps.
            We refer to this automatically-collected information as “Device
            Information.”
          </p>

          <p>We collect Device Information using the following technologies:</p>
          <p>
            &#8211; “Cookies” are data files that are placed on your device or
            computer and often include an anonymous unique identifier. For more
            information about cookies, and how to disable cookies, visit
            http://www.allaboutcookies.org.
          </p>
          <p>
            &#8211; “Log files” track actions occurring on the Site, and collect
            data including your IP address, browser type, Internet service
            provider, referring/exit pages, and date/time stamps.
          </p>
          <p>
            &#8211; “Web beacons,” “tags,” and “pixels” are electronic files
            used to record information about how you browse the site or use our
            apps.
          </p>

          <p>
            Additionally when you install our apps make a purchase or attempt to
            make a purchase through the apps, we collect certain information
            from you, including your name, billing address, shipping address,
            payment information (including credit card numbers, email address,
            and phone number. We refer to this information as “Order
            Information.”
          </p>
          <p>
            When we talk about “Personal Information” in this Privacy Policy, we
            are talking both about Device Information and Order Information.
          </p>

          <h2>How do we use your personal information?</h2>
          <p>
            We use the Order Information that we collect generally to fulfill
            any orders placed through the site or apps (including processing
            your payment information, arranging for shipping, and providing you
            with invoices and/or order confirmations). Additionally, we use this
            Order Information to:
          </p>

          <p>&#8211; Communicate with you;</p>
          <p>&#8211; Screen our orders for potential risk or fraud; and</p>
          <p>
            &#8211; When in line with the preferences you have shared with us,
            provide you with information or advertising relating to our products
            or services.
          </p>
          <p>
            &#8211; We use the Device Information that we collect to help us
            screen for potential risk and fraud (in particular, your IP
            address), and more generally to improve and optimize our Site (for
            example, by generating analytics about how our customers browse and
            interact with the Site, and to assess the success of our marketing
            and advertising campaigns).
          </p>

          <h2>Sharing Your Personal Information</h2>
          <p>
            We share your Personal Information with third parties to help us use
            your Personal Information, as described above. For example, we use
            Shopify to power our apps&#8211;you can read more about how Shopify
            uses your Personal Information here:
            https://www.shopify.com/legal/privacy. We also use Google Analytics
            to help us understand how our customers use the Site&#8211;you can
            read more about how Google uses your Personal Information here:
            https://www.google.com/intl/en/policies/privacy/. You can also
            opt-out of Google Analytics here:
            https://tools.google.com/dlpage/gaoptout.
          </p>
          <p>
            Finally, we may also share your Personal Information to comply with
            applicable laws and regulations, to respond to a subpoena, search
            warrant or other lawful request for information we receive, or to
            otherwise protect our rights.
          </p>

          <h2>Behavioral Advertising</h2>
          <p>
            [As described above, we use your Personal Information to provide you
            with targeted advertisements or marketing communications we believe
            may be of interest to you. For more information about how targeted
            advertising works, you can visit the Network Advertising
            Initiative’s (“NAI”) educational page at
            http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
          </p>
          <p>You can opt out of targeted advertising by:</p>
          <p>
            &#8211; Facebook: https://www.facebook.com/settings/?tab=ads
            <br />
            &#8211; Google: https://www.google.com/settings/ads/anonymous
          </p>
          <p>
            Additionally, you can opt out of some of these services by visiting
            the Digital Advertising Alliance’s opt-out portal at:
            http://optout.aboutads.info/.
          </p>

          <h2>Do Not Track</h2>
          <p>
            Please note that we do not alter our site&#8217;s or apps data
            collection and use practices when we see a Do Not Track signal from
            your browser.
          </p>

          <h2>Your Rights &#8211; European Residents</h2>
          <p>
            If you are a European resident, you have the right to access
            personal information we hold about you and to ask that your personal
            information be corrected, updated, or deleted. If you would like to
            exercise this right, please contact us through the contact
            information below.
          </p>
          <p>
            Additionally, if you are a European resident we note that we are
            processing your information in order to fulfill contracts we might
            have with you (for example if you make an order through the site or
            apps), or otherwise to pursue our legitimate business interests
            listed above. Additionally, please note that your information will
            be transferred outside of Europe, including to Canada and the United
            States.
          </p>

          <h2>Data Retention</h2>
          <p>
            When you place an order through the Site, we will maintain your
            Order Information for our records unless and until you ask us to
            delete this information.
          </p>

          <h2>Changes</h2>
          <p>
            We may update this privacy policy from time to time in order to
            reflect, for example, changes to our practices or for other
            operational, legal or regulatory reasons.
          </p>

          <h2>Contact Us</h2>
          <p>
            For more information about our privacy practices, if you have
            questions, or if you would like to make a complaint, please contact
            us by e-mail at feed@zengapps.com
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default PrivacyPolicyPage
